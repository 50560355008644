<template>
  <el-main>
    <!-- 表单 -->
    <el-form class="el-form-search" label-width="110px">
      <el-form-item label="用户昵称：">
        <el-input size="small" placeholder="请输入用户昵称：" v-model="searchForm.nickname"></el-input>
      </el-form-item>
      <el-form-item label="创建时间：">
        <el-date-picker
          size="small"
          class="search_input"
          v-model="searchForm.time"
          type="daterange"
          range-separator="~"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="timestamp"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label=" " label-width="20px">
        <el-button type="primary" size="small" @click="getList">搜索</el-button>
        <el-button size="small" @click="exportToExcel">导出</el-button>
        <el-button type="text" size="small" @click="cancelSearch">清空搜索条件</el-button>
      </el-form-item>
    </el-form>
    <!-- 列表 -->
    <el-table :data="list" :header-cell-style="{ 'background-color': '#F8F9FA' }" fit id="table">
      <template slot="empty">
        <No />
      </template>
      <el-table-column prop="name" label="表单名称" align="center" min-width="140"></el-table-column>
      <el-table-column label="用户信息" align="center" min-width="160">
        <template slot-scope="scope">
          <div class="userInfo">
            <el-image :src="scope.row.avatar"></el-image>
            <div class="nickname">{{ scope.row.nickname }}</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="create_time" label="创建时间" align="center" width="160">
        <template slot-scope="scope">{{ scope.row.create_time | getDateformat }}</template>
      </el-table-column>
      <el-table-column label="核销状态" align="center" width="160" fixed="right">
        <template v-slot="{ row }">{{ row.status == 2 ? '已核销' : '未核销' }}</template>
      </el-table-column>
      <el-table-column label="邀约人" align="center" width="160" fixed="right">
        <template v-slot="{ row }">{{ row.staff_info ? row.staff_info.staff_name : '--' }}</template>
      </el-table-column>
      <el-table-column label="是否领取物料" align="center" width="160" fixed="right">
        <template v-slot="{ row }">
          <el-switch @change="changeGetStatus(row)" v-model="row.is_get" :active-value="1" :inactive-value="0"></el-switch>
        </template>
      </el-table-column>
      <template v-if="list.length > 0">
        <div v-for="(item, index) in list[0].form_data" :key="index">
          <el-table-column :label="item.info ? item.info.name : ''" align="center" :key="index" v-if="item.type == 'checkbox' || item.type == 'area'" width="150" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.form_data[index].value.toString() }}</template>
          </el-table-column>
          <el-table-column :label="item.info ? item.info.name : ''" align="center" v-else-if="item.type == 'identity'" width="170">
            <template slot-scope="scope">{{ scope.row.form_data[index].value }}</template>
          </el-table-column>
          <el-table-column :label="item.info ? item.info.name : ''" align="center" v-else-if="item.type == 'image'" width="250">
            <div class="imagebox" slot-scope="scope">
              <el-image :src="i_item" v-for="(i_item, i_index) in scope.row.form_data[index].value" :key="i_index" :preview-src-list="scope.row.form_data[index].value"></el-image>
            </div>
          </el-table-column>
          <el-table-column :label="item.info ? item.info.name : ''" align="center" v-else-if="item.type == 'daterange'" width="180">
            <template slot-scope="scope">{{ scope.row.form_data[index].valueStart }} - {{ scope.row.form_data[index].valueEnd }}</template>
          </el-table-column>
          <el-table-column :label="item.info ? item.info.name : ''" align="center" v-else-if="item.type != 'banner' && item.type != 'protocol'" min-width="120" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.form_data[index].value }}</template>
          </el-table-column>
        </div>
      </template>
    </el-table>

    <Paging :total="total_number" :page="searchForm.page" :pageNum="searchForm.rows" @updatePageNum="updateData"></Paging>
  </el-main>
</template>

<script>
import { getDateformat } from '@/util/getDate';
import Paging from '@/components/paging';
import FileSaver from 'file-saver';
import XLSX from 'xlsx';
export default {
  components: {
    Paging,
  },
  data() {
    return {
      searchForm: {
        page: 1,
        rows: 10,
        nickname: '',
        time: null,
        form_id: '',
      },
      total_number: 0,
      list: [],
    };
  },
  filters: {
    getDateformat: getDateformat,
  },
  created() {
    this.searchForm.form_id = this.$route.query.form_id;
    if (this.$route.query.name) this.searchForm.nickname = this.$route.query.name;
    this.getList();
  },
  methods: {
    changeGetStatus(data) {
      let target = data.is_get;
      this.$axios
        .post(this.$api.yiyun.changeGet, {
          id: data.id,
          is_get: target,
        })
        .then(res => {
          if (res.code != 0) {
            this.$message.error('设置失败');
            data.is_get = target ? 0 : 1;
          }
        });
    },
    cancelSearch() {
      this.searchForm = {
        page: 1,
        rows: 10,
        nickname: '',
        time: null,
        form_id: this.searchForm.form_id,
      };
      this.getList();
    },
    getList() {
      let data = {
        page: this.searchForm.page,
        rows: this.searchForm.rows,
        nickname: this.searchForm.nickname,
        form_id: this.searchForm.form_id,
      };
      if (this.searchForm.time) {
        data.start_time = this.searchForm.time[0] / 1000;
        data.end_time = this.searchForm.time[1] / 1000;
      }
      this.$axios.post(this.$api.customform.dataList, data).then(res => {
        if (res.code == 0) {
          this.total_number = res.result.total_number;
          let list = res.result.list;
          list.forEach(item => {
            item.form_data = JSON.parse(item.form_data);
          });
          this.list = list;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    updateData(val, status) {
      if (status == 0) {
        this.searchForm.rows = val;
        this.getList();
      } else {
        this.searchForm.page = val;
        this.getList();
      }
    },
    exportToExcel() {
      // let fix = document.querySelector('.el-table__fixed');
      // let wb = '';
      // let xlsxParam = { raw: true }; //转换成excel时，使用原始的格式
      // if (fix) {
      //   //判断要导出的节点中是否有fixed的表格，如果有，转换excel时先将该dom移除，然后append回去 可以避免行重复
      //   wb = XLSX.utils.table_to_book(document.querySelector('#table').removeChild(fix), xlsxParam);
      //   document.querySelector('#table').appendChild(fix);
      // } else {
      //   wb = XLSX.utils.table_to_book(document.querySelector('#table'), xlsxParam);
      // }
      // let wbout = XLSX.write(wb, {
      //   bookType: 'xlsx',
      //   bookSST: true,
      //   type: 'array',
      // });
      // try {
      //   //文件名可以自定义
      //   FileSaver.saveAs(new Blob([wbout], { type: 'application/octet-stream' }), '表单收集.xlsx');
      // } catch (e) {
      //   if (typeof console !== 'undefined') console.log(e, wbout);
      // }
      // return wbout;
      let obj = {
        nickname: this.searchForm.nickname,
        form_id: this.searchForm.form_id,
      };
      if (this.searchForm.time) {
        obj.start_time = this.searchForm.time[0] / 1000;
        obj.end_time = this.searchForm.time[1] / 1000;
      }
      this.$axios.post(this.$api.yiyun.customFormExport, obj).then(res => {
        if (res.code == 0) {
          this.$alert('导出中，请耐心等待，稍后跳转到导出文件中下载', '提示', {
            confirmButtonText: '确定',
            callback: action => {},
          });
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background: #fff;
}

.el-table {
  .userInfo {
    display: flex;
    align-items: center;
    justify-content: center;

    .el-image {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      margin-right: 10px;
      flex-shrink: 0;
    }

    .nickname {
      flex: 1;
      text-align: left;
    }
  }

  .imagebox {
    display: flex;
    flex-wrap: wrap;

    .el-image {
      height: 70px;
      width: auto;
      flex-shrink: 0;
      margin-bottom: 10px;
      margin-right: 10px;
    }
  }
}
</style>
